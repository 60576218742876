import React from 'react';

const ImagesFromFolder = ({ title, fileNames, folderPath }) => {
    
    return (
        <>
        <td>
            <span className = "tagsLabel">{title}</span>
        </td>
        <div>
            {fileNames.map((image, index) => (
            <img
            key={index}
            src={folderPath +'/'+ image + '.svg'}
            alt={image}
            className="tag-image"
            />
            ))}
        </div>
        </>
    );
};

export default ImagesFromFolder;
