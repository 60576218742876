import React from 'react';
import { Link } from 'react-router-dom';
import ImagesFromFolder from './ImagesFromFolder';

function Skills() {
  return (
    <div>
        <h2>🛠️ Software Skills</h2> 
        <table>
            <tr>
                <td className="portfolio-td" colSpan={2} rowSpan={2}>
                    <Link className="portfolio-link" to="/projects">Check Out My Portfolio</Link>
                </td>
            </tr>
            <tr>
            </tr>
            <tr>
                <ImagesFromFolder 
                title = 'Languages'
                fileNames={['python', 'csharp', 'java', 'typescript']} 
                folderPath='./skills/languages'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Frontend'
                fileNames={['react', 'next-js', 'wpf']} 
                folderPath='./skills/frontend'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Backend'
                fileNames={['flask', 'net', 'spring', 'node_js']} 
                folderPath='./skills/backend'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Database'
                fileNames={['postgres', 'mongodb', 'sqlite']} 
                folderPath='./skills/database'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Cloud'
                fileNames={['aws', 'gcp', 'azure']} 
                folderPath='./skills/cloud'/>
            </tr>
            <tr>
                <ImagesFromFolder 
                title='Tools'
                fileNames={['githubactions', 'docker', 'auth0']} 
                folderPath='./skills/tools'/>
            </tr>
        </table>
    </div>
  );
}

export default Skills;