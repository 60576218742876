// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';

import Home from './components/Home';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="main-body">
        <nav>
          <ul className="navBar">
            <li className = "navBarLinksLi"><Link className = "navBarLinks" to="/">Bio</Link></li>
            <li className = "navBarLinksLi"><Link className = "navBarLinks" to="/projects">Portfolio</Link></li>
            <li className = "navBarLinksLi"><Link className = "navBarLinks" to="/blog">Journal</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/blog" element={<Blog/>} />
        </Routes>
      </div>
      <Footer/> 
    </Router>
  );
}

export default App;
