import React, { useState } from "react";
import {blogPosts} from './content/BlogContent.jsx';
import './Blog.css';
import parse from 'html-react-parser';  // Install and import this library to parse HTML strings

function Blog() {
  const [openedPostId, setOpenedPostId] = useState(null);

  const handleClick = postId => {
    if (postId === openedPostId) {
      setOpenedPostId(null);
    } else {
      setOpenedPostId(postId);
    }
  };

  return (
    <div className="blog">
      <h1 className="blog-title">Feathers Codes Blog</h1>
      {blogPosts.sort((a, b) => b.id - a.id).map(post => (
        <div key={post.id} className="blog-post" onClick={() => handleClick(post.id)}>
          <h2 className = "blog-post-title">{post.title}</h2>
          {post.id === openedPostId
            ? parse(post.content)
            : <>
                {parse(post.content.slice(0, 300))}
                {post.content.length > 300 && '...'}
              </>}
        </div>
      ))}
    </div>
  );
}

export default Blog;
