import React from 'react';
import Skills from './Skills.jsx';

function AboutMeContent() {
  return (
    <div className='about-text'>
      <div className="subHeading">
          <h2>✨ Experience</h2>
          <p>Software Engineer at TikTok. Previously worked at CORE Studio building structural engineering web and desktop applications. 
            Coming from a background in both physical 🏛️ and digital engineering 💻, I provide a unique perspective to 
            solving user challenges.</p>
      </div>

      <div className="subHeading">
          <h2>🦋 Career Transition</h2>
          <p>Started my career as a structural engineer P.E. 🏗️ in NYC. While working in a PM role and working
            on complex projects, I began experimenting with Python 🐍 scripts during the steel design of the&nbsp;
            <a className="links" href="https://www.adjaye.com/work/princeton-university-art-museum/" target="_blank" rel="noopener noreferrer">
              Princeton Art Museum</a>&nbsp;to automate time-consuming tasks that required rapid iteration.</p>
      </div>

      <div className="subHeading">
          <h2>🎓 Education</h2>
          <p>Before writing software, I studied structural engineering at Cornell (Go Big Red! 🐻) and UIUC where I dabbled with Matlab 
            for structural and finite elment analysis. I began a post-bacc B.S. at Oregon State University in September 2020, 
            and was exposed to the full world of CS knowledge, culminating in an exploration of technical trading during my capstone.</p>
      </div>
      <div className="subHeading">
        <Skills />
      </div>
      <div className="subHeading">
          <h2>🤟 Personal Tidbits</h2>
          <p>Outside of writing software, I'm an avid 🏒 fan, 🍕 eater, and 🐈 lover.</p>
          <figure>
            <img className = "Ollie" src="/aboutOllie.jpeg" alt="Ollie's Portrait" />
            <figcaption className = "caption">Code Whiskerer</figcaption>
          </figure>
      </div>
  </div>
  );
}

export default AboutMeContent;