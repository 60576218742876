import './Home.css';
import React from 'react';
import AboutMeContent from './content/AboutMeContent.jsx';

function Home() {
  return (
    <div className = "home-flex">
      <div className="home">
        <h1 className="h1-home">Hi, I'm Russell</h1>
        <h3 className="h1-home">A Full-Stack Dev / Career-Shifter / CS Grad</h3>
        <div className = "profile">
            <img src="/profile_feathers_live_cropped.png" alt="Profile" className="profile-pic"/>
        </div>
        <div className="social-links">
          <a className="links" href="https://www.linkedin.com/in/russell-feathers/" target="_blank" rel="noopener noreferrer">
            <img className="links" src="/linkedin_icon.png" alt="LinkedIn" />
          </a>
          <a href="https://github.com/birdman093" target="_blank" rel="noopener noreferrer">
            <img className="links github-icon-home" src={getComputedStyle(document.querySelector(':root')).getPropertyValue('--github-icon')} alt="GitHub" />
          </a>
          <a href="mailto:russfeathers@gmail.com"><img className = "links" src = "/email.png" alt = "email"></img
          ></a>
        </div>
      </div>
      <div className ="about">
        <AboutMeContent/>
      </div>
    </div>
  );
}

export default Home;

