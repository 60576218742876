export const coreStudioProjects = [
  {
    title: "AutoSTL",
    img: "./autoStl.png",
    github: null,
    gif: "./autoStl.gif",
    live: null,
    technologies: ["ASP.Net Core", "WPF", "MongoDB", "Azure App Service", 
      "Github Actions CI/CD", "Tekla API", ".NET"],
    shortDescription: "Automated precise steel connection 3D design and modeling",
    longDescription: 
    <>
      <p><span>
        AutoSTL is integrated with Trimble Tekla 3D modeling software to automate the detection, 
        design, and modeling of different structural steel connections based on rigorous and precise 
        internal detailing and design standards. Conventional software solutions did not meet the 
        requirements of the construction engineering group. 
      </span></p>
      <p><span>
        Our CORE studio team created a system that was extensible to many types of connections, 
        easy to understand, and accurate. AutoSTL transformed a ~8-minute bug-ridden manual 
        modeling process per connection down to seconds, enabling it to be used rapidly on 
        stadium and airport projects.
      </span></p>
    </>
  },
  {
    title: "Cala",
    img: "./cala.png",
    github: null,
    gif: "./cala.gif",
    live: null,
    technologies: 
    ["WPF", "SQLite", "Procore API", ".NET"],
    shortDescription: "Construction file download manager and tracking system",
    longDescription: 
    <>
      <p ><span>
        Desktop software utilizing Procore API that automated an aggravating process of sorting, 
        storing, and tracking construction submittals and requests for information (RFI's) during 
        the most chaotic part of the project: construction. For example, some of TT's most 
        significant airport and stadium projects require processing over 50,000 files.
      </span></p>
      <p><span>Our CORE studio team created a system that stored the latest copies of 
        submittals/rfis to network drives, reported items that required responses, and 
        enabled users to focus less on admin. CALA has an estimated annual savings of $250k 
        in skilled labor time.
      </span></p>
    </>
  },
  {
    title: "Graph Analysis",
    img: "./tribArea.png",
    github: null,
    gif: null,
    live: null,
    technologies: 
    [".NET", "GH API", "Data Structures", "Matrices"],
    shortDescription: "Graph algorithmic design for building design and analysis",
    longDescription: 
    <>
      <p><span>
        Structural engineers are often asked to make sizing assessments early 
        in the design process before they've had a chance to get their structural design 
        models vetted. CORE studio graph structural analysis library, in conjunction with 
        CORE framing repair library, provides a way to get early designs based on 
        preliminary architectural models. These design tools enable users to visualize 
        and track loading from start to finish and perform a simplified sizing analysis.
      </span></p>
      <p><span>
        The development required extensive usage of data structures and focused on 
        graph-based search(BFS and DFS) and geometry-based manipulation (dot products! and angles) 
        to assign loading to different members under non-ambiguous loading conditions. 
        We generalized the library for multiple platform integrations, including Trimble 
        Tekla, Autodesk Revit, and Grasshopper.  
      </span></p>
    </>
  }
]; 

export const osuProjects = [
  {title: "Project Air Ball",
  img: "./air-ball-picks.png",
  github: "https://github.com/birdman093/air-ball",
  gif: null,
  live: "https://air-ball.vercel.app",
  technologies:
  ["Next.js", "DyanmoDB", "Python", "NBA-API", "RapidAPI", "Docker", 
  "AWS - Lambda, EventBridge, CloudWatch, SNS"],
  shortDescription: "Machine Learning Studies on the NBA",
  longDescription: 
  <>
    <p ><span >Project Air Ball is a machine learning model, seasonal data aggregation, and 
    frontend built using data from the NBA API </span></p>
  </>

  },
  {
    title: "Cloud Trading Optimization",
    img: "./tradingoptimization.png",
    github: null,
    gif: "https://vimeo.com/867341150/8006e0b3dc",
    live: null,
    technologies:
    ["Flask", "MongoDB", "AWS EB", "Backtesting.py", "Pymoo",
    "React.js", "Plotly.js"],
    shortDescription: "Optimization of algorithm technical trading parameters for highly-volatile stocks",
    longDescription: 
    <>
      <p ><span >Our team of OSU students worked with a project mentor to 
        develop an application that uses multi-objective optimization to 
        find optimized parameters for different trading algorithms. With 
        multi-objective optimization, there is generally more than one solution, 
        and the application generates a pareto distribution of all possible 
        optimized solution sets. The solutions are displayed to the user in a 
        scatter plot, where the user can hover over each plot point to 
        reveal more detailed results. Therefore, the user can choose which
         solution fits their needs best. </span></p>
      <p ><span >After the optimization, the results are stored in a database, 
        allowing the user to retrieve past results if necessary. Optimized results can 
        then be used on sites such as QuantConnect to trade based on the 
        different buy/ sell signals each algorithm yields.</span></p>
    </>
  },
  {
    title: "Z-Guess",
    img: "./z-guess2023.png",
    github: "https://github.com/birdman093/z-guess",
    gif: "https://vimeo.com/867346718/2dd9bd142d",
    live: null,
    technologies:
    ["React.js", "Node.js", "Express.js", "Postgres", "RapidAPI Zillow.com", "Railway", "Vercel"],
    shortDescription: "Property management and house pricing assessment based on Zillow",
    longDescription: 
    <>
      <p ><span >Z-Guess enables users to track sell-price estimates for house listings on 
        Zillow and generates a score based on "closeness" to the final sale price. Z-Guess 
        leverages the RapidAPI Zillow.com API to access real-time data and updates and 
        stores data in a PostgreSQL database hosted on Railway. Development started as 
        part of Software Development I at Oregon State University in 2022 and was 
        inspired by many disagreements on house prices with my best friend.
      </span></p>
    </>
  }
];

export const hackathon = [
  {
    title: "Crash",
    img: "./crash.png",
    github: "https://github.com/clicketyclackety/Crash",
    gif: "https://camo.githubusercontent.com/29f4dbce4879a43e4bf0f3a71689a576cbfcdda49481911c3d812b8c752dba3b/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f6f4e75593077736944563558466d59754e772f67697068792e676966",
    live: null,
    technologies: 
    ["ASP.Net Core", "SignalR - Websockets", "Azure App Service", "SQLite", "Rhino API"],
    shortDescription: "Cloud-based colloborative modeling for 3D modeling software, Rhino",
    longDescription: 
    <>
      <p ><span >AEC Tech - New York Hackathon winning project in Fall 2022. In 26 hours, 
        our team created Crash (term for a herd of Rhinos) a plugin/application prototype that allowed multiple users to collaborate 
        on a single cloud-based Rhino model. Users can lock elements, relinquish them, 
        and see what their fellow users work on in real-time.
      </span></p>
    </>
  },
  {
    title: "Clippy-AI",
    img: "./clippy.png",
    github: "https://github.com/rfeathers068/clippy-ai",
    gif: "./clippy.gif",
    live: null,
    technologies: 
    ["OpenAI API", "Flask", "PyRevit API", "WPF"],
    shortDescription: "OpenAI assistant integration for 3D modeling software, AutoDesk Revit",
    longDescription: 
    <>
      <p ><span >AEC Tech - New York Hackathon honorable mention project in Fall 2023. 
        Our team brought Clippy back to life as part of an OpenAI integration with 
        Autodesk Revit. By the end of our 26 hours of hacking, we created an application 
        that enabled users to type commands from our UI within Revit and execute code 
        generated by OpenAI (with the help of some context and error handling) to 
        complete those commands.
      </span></p>
    </>
  }
  
];