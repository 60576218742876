import React, { useState } from 'react';
import './Projects.css';
import { coreStudioProjects, hackathon, osuProjects } from './content/ProjectContent.jsx';

function Projects() {

  const [popupProject, setPopupProject] = useState(null);

  const renderProject = (project, index) => (
    <div key={index} className="project-card" onClick={() => setPopupProject(project)}>
      <img className ="project-preview-img" src={project.img} alt={project.title}/>
      <h3>{project.title}</h3>
      {shortDescription(project.shortDescription)}
      {media(project.github, project.gif, project.live, 'social-links')}
      {technologies(project.technologies, 'technologies-card')}
    </div>
  );

  const shortDescription = (shortDescription) => {
    return (
      <div className='shortDescription'>
        <span><i>{shortDescription}</i></span>
      </div>
    )

  };

  const media = (gitHubLink, gifLink, liveLink, techClass) => {
    if (gitHubLink || gifLink || liveLink) {
      return (
        <div className={techClass}>
          {gitHub(gitHubLink)}
          {gif(gifLink)}
          {live(liveLink)}
        </div>
      );
    } else {
      return (<div></div>);
    }
  };

  const gitHub = (gitHubLink) => {
    if (gitHubLink) {
      return (
          <a className="links" href={gitHubLink} target="_blank" rel="noopener noreferrer"
          onClick={(event) => {
            event.stopPropagation();
          }}>
            <img className="links github-icon-home"
            src={getComputedStyle(document.querySelector(':root')).getPropertyValue('--github-icon')} alt="GitHub" />
          </a>
      );
    } else {
      return null;
    }
  };

  const gif = (gifLink) => {
    if (gifLink) {
      return (
        <a className="links" href={gifLink} target="_blank" rel="noopener noreferrer"
        onClick={(event) => {
          event.stopPropagation();
        }}>
            <img className="links" src="./video.png" alt="Video" />
        </a>
      );
    } else {
      return null;
    }
  };

  const live = (liveLink) => {
    if (liveLink) {
      return (
        <a className="links" href={liveLink} target="_blank" rel="noopener noreferrer"
        onClick={(event) => {
          event.stopPropagation();
        }}>
            <img className="links" src="./live.png" alt="Live" />
        </a>
      );
    } else {
      return null;
    }
  };

  const technologies = (techStack, techClass) => (
    <div className={techClass}>
      {techStack.map(tech => (
          <span key={tech} className="tags">{tech}</span>))
      }
    </div>
    );

  return (
    <div className="project-page">
      <h1 className="project-tagline">Solving Problems through Code</h1>
      <h2 className ="category-heading"><span className ="project-subheading">CORE Studio</span></h2>
      <h4>Automation of Engineering Workflows through Algorithms</h4>
      <div className="project-grid">
        {coreStudioProjects.map(renderProject)}
      </div>

      <h2 className ="category-heading"><span className ="project-subheading">Explorations</span></h2>
      <h4>Explorations of Modern Web Technologies</h4>
      <div className="project-grid">
        {osuProjects.map(renderProject)}
      </div>

      <h2 className ="category-heading"><span className ="project-subheading">Hackathons</span></h2>
      <h4>Building the future of A/E/C in 26 hours </h4>
      <div className="project-grid">
        {hackathon.map(renderProject)}
      </div>

      {popupProject && ( 
        <div className="project-popup" onClick={() => setPopupProject(null)}>
          <div className="popup-content">
            <img src={popupProject.img} alt={popupProject.title} className="popup-img" />
            <div>
              <button className="popup-button" onClick={() => setPopupProject(null)}>X</button>
              <h2>{popupProject.title}</h2>
              <div className="popup-text">
                {popupProject.longDescription}
              </div>
              {technologies(popupProject.technologies, 'technologies')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;
